export function getClientOriginUrl() {
  return typeof window !== "undefined" ? window.location.origin : "";
}

export function joinPath(...segments: any[]) {
  const parts = segments.reduce((parts, segment) => {
    if (parts.length > 0) {
      segment = segment.replace(/^\//, "");
    }
    segment = segment.replace(/\/$/, "");
    return parts.concat(segment.split("/"));
  }, []);
  const resultParts = [];
  for (const part of parts) {
    if (part === ".") {
      continue;
    }
    if (part === "..") {
      resultParts.pop();
      continue;
    }
    resultParts.push(part);
  }
  const url = resultParts.join("/");
  if (url.endsWith("/")) {
    return url.slice(0, -1);
  }
  return url;
}

export const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://soulbound.gg"
    : process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://staging.popdrip.xyz";
